import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Main/index.tsx";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PageTitle from '@/components/PageTitle';
import ImageBlock from '@/components/ImageBlock';
import whatWeDeliver from '@/assets/who-are-we/financial-insight.webp';
import ourSolutions from '@/assets/who-are-we/our-solutions.webp';
import ourClients from '@/assets/who-are-we/customised-solutions.webp';
import ourPointsOfDifference from '@/assets/who-are-we/api-iframes.webp';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <PageTitle mdxType="PageTitle">
      <Typography variant="h1" style={{
        flexBasis: '100%'
      }} mdxType="Typography">
  Who Are We?
      </Typography>
      <Typography variant="h5" style={{
        flexBasis: '100%'
      }} mdxType="Typography">
  Financial insights built by Fintechs for Fintechs.
      </Typography>
    </PageTitle>
    <Box padding={5} mdxType="Box">
      <ImageBlock Image={whatWeDeliver} title="What We Deliver" mdxType="ImageBlock">
        <p>{`TaleFin delivers Australia's most accurate and highest quality consumer
financial insight solutions. With secure access to customer-consented
financial data and powerful metrics that uncover valuable decision-making
information. Our consumer financial insights enable clients to reduce consumer
financial risk, create upsell opportunities, learn consumers' retail
preferences and empower clients to make informed decisions.`}</p>
      </ImageBlock>
    </Box>
    <Box padding={5} mdxType="Box">
      <ImageBlock Image={ourSolutions} title="Our Solutions" imageSide="right" mdxType="ImageBlock">
        <p>{`We're driven by building cutting edge innovative solutions in the financial
services sector that enables companies to use the power of financial data and
metrics to build intelligent financial solutions. By using accurate, verified
and consent driven information, TaleFin can offer a range of solutions that
enable you to make more informed and critical financial decisions.`}</p>
      </ImageBlock>
    </Box>
    <Box padding={5} mdxType="Box">
      <ImageBlock Image={ourClients} title="Our Mission" mdxType="ImageBlock">
  
Our clients and their customers depend on TaleFin to develop and deliver the most accurate financial management metrics currently in the market. With customised solutions for whatever your business requirements are and access to more reliable financial data, you can utilise and develop financial resolutions that work for you.
        <p>{`We give you the truest insight into customer’s bank statement financial data and provide you with increased processing speed, outfitted with the most authentic and comprehensive data to make informed choices quickly.`}</p>
      </ImageBlock>
    </Box>
    <ImageBlock Image={ourPointsOfDifference} title="Our points of difference" imageSide="right" mdxType="ImageBlock">
      <p>{`TaleFin's agile development team is capable of building custom metrics faster than anyone else in the business, allowing us to provide
unbeatable value. With rapid support, impressive uptime, and reliable consistent delivery of applications, TaleFin is a great partner to
help develop your business or product.`}</p>
      <p>{`Based in Brisbane, Australia; we are an Australian company serving the Australian market that was built by Fintechs for Fintechs.`}</p>
      <p>{`Using APIs and iFrames, we provide consent given customer financial data from the most up-to-date list of supported institutions available in Australia.`}</p>
    </ImageBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      